<template>
    <div class="EBCHome">
        <div class="header">
            <h4 title="CUCC商混EBC"></h4>
            <div class="user-info">
                <div class="item current-time">
                    {{ currentTime }}
                </div>
                <div class="item user-name">
                    <span>{{ userName }}</span>
                </div>
                <div class="item login-out" @click="signOut">
                    退出
                </div>
            </div>
        </div>
        <div class="container">
            <div class="blocks-con">
                <div class="row">
                    <div class="block block1" @click="toMenu('ebc_sk_erp')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>ERP系统</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>ERP系统</h4>
                            <span class="right-corner">ERP</span>
                        </div>
                    </div>
                    <div class="block block2" @click="toMenu('ebc_sk_shgk')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>综合管控</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>管控</h4>
                        </div>
                    </div>
                    <div class="block block3" @click="toMenu('ebc_sk_yybb')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>运营报表</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>运营报表</h4>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="block block4" @click="toMenu('ebc_sk_mrsj')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>每日数据</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>每日数据</h4>
                        </div>
                    </div>
                    <div class="block block5" @click="toMenu('ebc_sk_ldjsc')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>经营驾驶舱</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>经营驾驶舱</h4>
                        </div>
                    </div>
                    <div class="block block6" @click="toMenu('ebc_sk_znss')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>智能搜索</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>搜索</h4>
                        </div>
                    </div>
                    <div class="block block7" @click="toMenu('ebc_sk_zstp')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>知识图谱</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>知识图谱</h4>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="block block8" @click="toMenu('ebc_sk_tjfx')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>专题分析</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>专题分析</h4>
                        </div>
                    </div>
                    <div class="block block9">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>三维场站</p>
                            </div>
                        </div>
                        <div class="clock-hover Station">
                            <div @click="toMenu('ebc_sk_swcz','jn')">
                                <h4>济宁三维场站</h4>
                            </div>
                            <div @click="toMenu('ebc_sk_swcz','dy')">
                                <h4>东营三维场站</h4>
                            </div>
                        </div>
                    </div>
                    <div class="block block10" @click="toMenu('ebc_sk_sjzx')">
                        <div class="block-con">
                            <div>
                                <div class="pre-icon"></div>
                                <p>数据中心</p>
                            </div>
                        </div>
                        <div class="clock-hover">
                            <h4>数据中心</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from '@/utils/util';
import { reAddRouters, resetTokenAndClearUser } from '@/utils';
import cookie from '@/common/NIM/utils/cookie';
export default {
    components: {},
    props: {},
    data() {
        return {
            currentTime: '', // 当前时间
            userName: '', // 用户名
            menuList: [], // 菜单集合
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取当前时间
        getCurrentTime() {
            const currentTime = util.currentTimeFormat('yyyy-MM-dd HH:mm:ss');
            const time = currentTime.split(' ');
            const date = time[0].split('-');
            this.currentTime = date[0] + '年' + date[1] + '月' + date[2] + '日 ' + time[1];
        },
        // 退出系统
        signOut() {
            cookie.delCookie('isNIM');
            resetTokenAndClearUser();
            this.$router.replace({ name: 'login' });
            window.location.reload();
        },
        // 获取菜单
        getMenus() {
            this.$axios.get('/interfaceApi/basicplatform/usermenu?id=2b42ab32e9f4d9c5')
                .then(res => {
                    if (res) {
                        this.menuList = res.userMenus[0].children;
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 跳转菜单
        async toMenu(code, type) {
            if (code === 'ebc_sk_mrsj') {
                this.$router.push({ path: '/dailyData' });
                return;
            }
            const res = this.menuList.filter(item => item.code === code);
            if (res.length > 0) {
                let menuCode = '';
                if (res[0].code === 'ebc_sk_erp') {
                    menuCode = 'shebc';
                } else if ( res[0].code === 'ebc_sk_yybb') {
                    menuCode = 'bbgl';
                } else if ( res[0].code === 'ebc_sk_znss') {
                    menuCode = 'myquery';
                } else {
                    menuCode = res[0].code;
                }
                localStorage.setItem('menuCode', menuCode);
                // 重新添加路由信息
                reAddRouters();
                setTimeout(() => {
                    if (res[0].path) {
                        if (type) {
                            this.$router.push({
                                path: res[0].path,
                                query: {
                                    type: type,
                                },
                            });
                        } else {
                            this.$router.push({ path: res[0].path });
                        }
                    } else {
                        this.$message.warning('暂无页面');
                    }
                }, 0);
            } else {
                this.$message.warning('暂无权限');
            }
        },
    },
    created() {
        setInterval(() => {
            this.getCurrentTime();
        }, 1000);
        this.userName = this.$takeTokenParameters('Name');
        this.getMenus();
    },
    mounted() {},
};
</script>
<style lang="stylus">
.EBCHome
    height 100%
    background url(./images/bg.jpg) no-repeat
    background-size 100% 100%
    .header
        width 100%
        height 1rem
        background linear-gradient(90deg,rgba(14,26,61,1),rgba(27,51,94,1))
        border-bottom 4px solid rgba(5,123,244,0.2)
        display flex
        justify-content space-between
        padding 0 .2rem
        h4
            background url(./images/logo.png) no-repeat
            background-position center center
            background-size 100%
            width 2.62rem
            position relative
            &:after
                content ' '
                position absolute
                width 5.47rem
                height .45rem
                background url(./images/guang.png) no-repeat
                background-size 100% 100%
                bottom -.225rem
                left -1.5rem
        .user-info
            display flex
            color #fff
            .item
                vertical-align middle
                line-height 1rem
                margin 0 .2rem
                position relative
                &:after
                    content ''
                    width 2px
                    height .16rem
                    background rgba(5,123,244,1)
                    opacity 0.3
                    position absolute
                    right -.2rem
                    top .42rem
                &:last-child
                    &:after
                        display none
            .user-name
                .user-icon
                    width .4rem
                    height .4rem
                    display inline-block
                    background #C8E3FC
                    border-radius 50%
                    margin .2rem .2rem 0 0
            .login-out
                cursor pointer
    .container
        display flex
        align-items center
        justify-content center
        .blocks-con
            width 10.9rem
            height 6.2rem
            margin-top 1rem
            overflow hidden
            .row
                margin-bottom .2rem
                overflow hidden
                .block
                    height 1.93rem
                    float left
                    position relative
                    cursor pointer
                    overflow hidden
                    &:before
                        content ''
                        background url(./images/bg2.png) center center no-repeat
                        position absolute
                        left 0
                        top 0
                        width 100%
                        height 100%
                    .block-con
                        height 100%
                        display flex
                        justify-content center
                        .pre-icon
                            width .9rem
                            height .9rem
                            margin-top .4rem
                            background-size 100% 100%
                            background-repeat no-repeat
                        p
                            text-align center
                            color #fff
                            font-size 0.2rem
                    .clock-hover
                        display none
                        width 100%
                        height 100%
                        position absolute
                        left 0
                        top 0
                        background:linear-gradient(90deg,rgba(68,115,198,1) 0%,rgba(37,176,237,1) 100%);
                        &:before
                            content ''
                            background url(./images/hoverbg.png) center center no-repeat
                            position absolute
                            left 0
                            top 0
                            width 2rem
                            height 1.93rem
                        h4
                            text-align center
                            line-height 1.93rem
                            font-size .4rem
                            color #fff
                            position absolute
                            left 0
                            top 0
                            width 100%
                            height 100%
                        .right-corner
                            position absolute
                            right 0
                            bottom -.2rem
                            font-size 1rem
                            color rgba(255,255,255,.1)
                            font-weight bold
                    .Station
                        >div
                            height 50%
                            width 100%
                            position relative
                            h4
                                position absolute
                                top 50%
                                left 50%
                                transform translate(-50%, -50%)
                                width 2.04rem
                                height 0.44rem
                                font-size 0.24rem
                                line-height 0.42rem
                                border 1px solid rgba(32, 153, 212, 1)
                                background:linear-gradient(90deg,rgba(rgba(68, 116, 198, 1)) 0%,rgba(rgba(38, 174, 237, 1)) 100%);
                            &:hover
                                h4
                                    background #2A5DAE
                    &:hover
                        .clock-hover
                            display block
            .row
                .block1
                    width 4.5rem
                    background linear-gradient(-90deg,rgba(2,103,156,1) 0%,rgba(4,84,148,1) 100%)
                    .pre-icon
                        background-image url(./images/daimakuai.png)
                .block2
                    width 1.9rem
                    background linear-gradient(-90deg,rgba(155,133,94,1) 0%,rgba(114,93,64,1) 100%)
                    margin 0 .2rem
                    .pre-icon
                        background-image url(./images/guankongmoshi.png)
                .block3
                    width 4.1rem
                    background linear-gradient(-90deg,rgba(32,116,122,1) 0%,rgba(9,91,111,1) 100%)
                    .pre-icon
                        background-image url(./images/baobiao.png)
                .block4
                    width 3.2rem
                    background linear-gradient(-90deg,rgba(98,139,83,1) 0%,rgba(67,103,60,1) 100%)
                    .pre-icon
                        background-image url(./images/bi.png)
                .block5
                    width 3.2rem
                    background linear-gradient(-90deg,rgba(64,103,162,1) 0%,rgba(42,67,123,1) 100%)
                    margin 0 .2rem
                    .pre-icon
                        background-image url(./images/jiashicang.png)
                .block6
                    width 1.95rem
                    background linear-gradient(-90deg,rgba(2,104,156,1) 0%,rgba(4,83,147,1) 99%)
                    margin-right .2rem
                    .pre-icon
                        background-image url(./images/sousuo.png)
                .block7
                    width 1.95rem
                    background linear-gradient(-90deg,rgba(155,133,94,1) 0%,rgba(114,93,64,1) 100%)
                    .pre-icon
                        background-image url(./images/zhishitupu.png)
                .block8
                    width 3.2rem
                    background:linear-gradient(-90deg,rgba(32,116,122,1) 0%,rgba(9,91,111,1) 100%);
                    .pre-icon
                        background-image url(./images/tongji.png)
                .block9
                    width 3.2rem
                    background:linear-gradient(-90deg,rgba(2,103,156,1) 0%,rgba(4,84,148,1) 100%);
                    margin 0 .2rem
                    .pre-icon
                        background-image url(./images/sanwei.png)
                .block10
                    width 4.1rem
                    background:linear-gradient(-90deg,rgba(32,116,122,1) 0%,rgba(9,91,111,1) 100%);
                    .pre-icon
                        background-image url(./images/datacenter.png)
</style>